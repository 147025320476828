<template functional>
	<div class="component c-slideshow c-slideshow--banner">
		<div class="c-slideshow__item position-relative">
			<div
				class="c-slideshow__media bg-color-black position-relative z-index-1"
			>
				<picture
					class="utility-position-center height-100 width-100"
					v-if="
						props.data[0].primary_image &&
							props.data[0].primary_image.images.length
					"
				>
					<source
						class=""
						:srcset="$options.get_image_resizer_url(`${$root.proxy}${image.image}`)"
						:alt="image.alt"
						:media="`(min-width: ${image.breakpoint}px)`"
						v-for="(image, index) in props.data[0].primary_image
							.images"
						:key="index"
					/>
					<img
						class="object-fit-cover height-100 width-100"
						:src="$options.get_image_resizer_url(`${$root.proxy}${props.data[0].primary_image.images[0].image}`)"
						:alt="props.data[0].primary_image.images[0].alt"
					/>
				</picture>
			</div>
			<div
				class="c-slideshow__details header-font line-height-100 position-absolute bottom-0 left-0 padding-b-32 padding-l-32 text-transform-uppercase bp-1024:padding-b-64 bp-1024:padding-l-64 z-index-2"
			>
				<div
					class="c-slideshow__subtitle text-muted-on-dark font-size-16 bp-1024:font-size-30"
				>
					{{ props.data[0].subtitle }}
				</div>
				<div
					class="c-slideshow__title text-on-primary font-size-30 bp-1024:font-size-48"
				>
					{{ props.data[0].title }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get_image_resizer_url } from '@/helpers';

export default {
	name: 'SlideshowBanner',

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

  get_image_resizer_url(image_url) {
    return get_image_resizer_url(image_url);
  },
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	&__media {
		height: 300px;
		&::before {
			content: '';
			background: linear-gradient(
				rgba(0, 0, 34, 0) 0%,
				rgba(0, 0, 34, 0.8) 100%
			);
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
	}
}

@media screen and (min-width: 451px) {
	.c-slideshow {
		&__media {
			height: 500px;
		}
	}
}
</style>
